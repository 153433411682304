import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'Projects | Brilliant Sanitation (U) Ltd';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 team-h2">Recent Projects</h2>

         

           <table className='team-table'>
    
    <tr>
      <th>S/No.</th>
      <th>Organization</th>
      <th>Project</th>
      <th className='date-col'>Date</th>
      <th>Brief</th>
      <th>Role to be played by The Eight Investments Company Limited </th>
    </tr>
  </table>
</div>
           
    </section>
  )
}

export default Team