import React from "react";

import Carousel from 'react-bootstrap/Carousel';

function CarouselFade() {
  return (
    <Carousel fade className="home-slides-new">
      <Carousel.Item>
        <img
          className="d-block w-100"
          // src="./images/4.jpeg"
          src="./images/2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3 className="slide-h3">Brilliant Sanitation (U) Ltd</h3>
          <p className="slide-p slide-p1">"Let's Control It"</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          //  src="./images/1.jpg"
          src="./images/9.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          {/* <h3>We're Prominent In Engineering Works</h3> */}
          <p className="slide-p">We provide toilet emptying services</p>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/17.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p">
 We are trusted for high-quality sanitation services
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/3a.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
           We also do community sensitization 
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30a.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          {/* <h3>We Plan, Schedule, Execute & Control Projects
          </h3> */}
          <p className="slide-p slide-p4">
           We are also prominent in construction services 
          </p>
        </Carousel.Caption>
        
      </Carousel.Item>
      
     
     
      
    </Carousel>
  );
}

export default CarouselFade;