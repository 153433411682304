import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="First slide"
        />

      </Carousel.Item>
  
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/25.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>

       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/68.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/21.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/170.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/171.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/174.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/266.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/267.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/268.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/269.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/270.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
     
    </Carousel>
  );
}

export default TelecomCarousel;