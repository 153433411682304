import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaHandshake, FaCheck,FaCog,FaUsers,FaLeaf} from 'react-icons/fa';
import {GiMoneyStack} from 'react-icons/gi'
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Brilliant Sanitation (U) Ltd | Let's Control It";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a'>
          <div className='circle1'>
          </div>
          <div className='circle2'>
          </div>
          <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'>
                 <strong>Brilliant Sanitation (U) Ltd</strong>  is a duly registered Ugandan based company providing services to the government of Uganda, private entities and to non-governmental organizations. Our services include toilet emptying using both cesspool trucks and gulping technology, soak pit setting, soak pit upgrading, soak pit construction, plumbing works, construction of toilets, construction of septic tanks, consultancy works, supply of sanitation equipment and compound design.
                  </p>
                  <p className='home-p'>
                    Our professional and friendly staff are trained to give our clients the best customer experience. All our customer engagements begin with a free site survey from which we outline the details of the sanitation facility and the emptying mechanism to be used. More so, we have trucks of different capacities which makes our charges appropriate for different customer needs and sanitation facility sizes. 
                  </p>



 
        </div>

    </section>
  {/*<section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 128, 0.706),rgba(0, 0, 0, 0.806)), url("./images/2.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'5rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Objectives</h2>
            <div className='pledge-line'></div>
         
         <p>
          <span className='quote-cover'><FaCheckCircle></FaCheckCircle></span> 
 To meet client’s objectives through creativity, invention and service orientation.
</p>
 <p>
  <span className='quote-cover'><FaCheckCircle></FaCheckCircle></span> 
 To raise the standards of the organization in the country and beyond through providing
high quality products and services in an effective and efficient way.
</p>

        </div>
    </section> */}
    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     {/* <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2> */}
    <div className='line1'></div>
   
    <div className='home-services-container'>
      {/* <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div> */}
       {/* <div className='home-services-data'> */}
       {/* <div className='home-services-info '> */}

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Client-Oriented</h3>
          <p>Our on-site waste evaluations allow us to provide customized solutions to our clients in partnership with local stakeholders. We’re constantly looking for ways to simplify your waste-handling processes & reduce your capacity by tailoring our services to your specific needs.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><GiMoneyStack></GiMoneyStack></span>
          </div>
          <h3 className='home-service-title'>Value for Money</h3>
          <p>
            At Brilliant Sanitation (U) Ltd, there is no hidden cost so you never pay for more than you need. In addition to the services & products that we provide, we also empower our clients with information & services that add value. 
            </p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Efficiency</h3>
          <p>
            We are committed and pride ourselves on delivering the most efficient services & products when and where you need it in order to achieve quantifiable results with practical solutions.
            </p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCog></FaCog></span>
          </div>
          <h3 className='home-service-title'>Innovation</h3>
          <p>
           We provide a service that stands out through our creativity & cutting-edge technological, contractual and social solutions. Our approach allows us to avoid work disruptions & ensure you have the most resourceful waste management solutions for your waste.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaUsers></FaUsers>  </span>
          </div>
          <h3 className='home-service-title'>Social Responsibility</h3>
          <p>
            Our Business model is based around the individual, clients and communities that we serve therefore we strive to transform their lives by giving back to the communities where we operate.
            </p>
        </div>

         <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaLeaf></FaLeaf>  </span>
          </div>
          <h3 className='home-service-title'>Environmental Compliance</h3>
          <p>
            We are very mindful about complying with today’s complex and changing environmental regulations. We’ll partner with you every step of the way to ensure proper handling and disposal of your waste accordingly.


            </p>
        </div>

    </div>
    
     


        {/* </div> */}


    {/* </div> */}



  </section>
   
      <section className='section-cover who-we-are-cover' >
      
     
          <div className='content-container vm-container'>
                 

                 <div className='vision-mission-content mission-info'>

                  

                    <article className='vision-mission-info vision-mission-info1 vision-mission-info2'>
                      
                          <h3 className='title-h3'>Our Partners</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                        <li>   National Environment Management Authority</li> 
<li> National Water and Sewerage Corporation</li> 
<li> Water for People</li> 
<li> GIZ</li>                      
<li>Water Aid</li>
<li>Kampala Capital City Authority</li>
<li>The Association of Uganda Emptiers Limited</li>
<li>Gulpers Association of Uganda Ltd</li>



                          </ul>
                          
                          

                    </article>

                    {/* <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article> */}


                 </div>
          </div>
    </section>
  
    </>
  )
}

export default Home