import Carousel from 'react-bootstrap/Carousel';

function BuildingsCarousel() {
  return (
    <Carousel  indicators={false} className="goals-carousel buildings" >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/259.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/132.jpg"
          alt="First slide"
        />
      </Carousel.Item>
      
    </Carousel>
  );
}

export default BuildingsCarousel;