import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = "Medical Equipment Rental Services | ELK'S Medicals Limited";

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Medical Equipment Rental Services</h3>
                       <p>ELK'S Medical Rental Services is able, in many cases, to provide equipment within 24 hours. We will deliver anywhere in Uganda when a contract is in place. Equipment from ELK'S Medical Rental Services meets or exceeds Ministry of Health requirements.</p>
                       <p>We rent out medical equipment by the day, week and month with the following benefits:</p>
                       <ul>
                    <li>Long-term and short-term rentals for meeting peak demand needs.</li>
<li>Quality equipment meets or exceeds MOH requirements.</li>
<li>Around-the-clock nationwide delivery with contract.</li>
<li>You may be able to apply your equity toward purchase.</li>
                     
                     </ul>

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services