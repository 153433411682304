import Carousel from 'react-bootstrap/Carousel';

function VisionCarousel() {
  return (
    <Carousel indicators={false} className='vision-slides'>
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/2.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/3.jpg"
          alt="First slide"
        />
        
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/4.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

        <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/6.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
      
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/7.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/8.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/9.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/10.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/11.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/12.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/13.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="First slide"
        />    
      </Carousel.Item>
       <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/15.jpg"
          alt="First slide"
        />    
      </Carousel.Item>

       

    </Carousel>
  );
}

export default VisionCarousel;