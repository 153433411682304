
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';

import Tool1 from '../components/Tool1';
import Tool2 from '../components/Tool2';
import Tool3 from '../components/Tool3';
import Tool4 from '../components/Tool4';
import Tool5 from '../components/Tool5';
import Tool6 from '../components/Tool6';
import Tool7 from '../components/Tool7';
import Tool8 from '../components/Tool8';
import Tool9 from '../components/Tool9';
import Tool10 from '../components/Tool10';
import Tool11 from '../components/Tool11';
import Tool12 from '../components/Tool12';
import Tool13 from '../components/Tool13';
import Tool14 from '../components/Tool14';
import Tool15 from '../components/Tool15';
import Tool16 from '../components/Tool16';
import Tool17 from '../components/Tool17';
import Tool18 from '../components/Tool18';
import Tool19 from '../components/Tool19';
import Tool20 from '../components/Tool20';
import Tool21 from '../components/Tool21';
import Tool22 from '../components/Tool22';
import Tool23 from '../components/Tool23';
import Tool24 from '../components/Tool24';
import Tool25 from '../components/Tool25';
import Tool26 from '../components/Tool26';
import Tool27 from '../components/Tool27';
import Tool28 from '../components/Tool28';
import Tool29 from '../components/Tool29';
import Tool30 from '../components/Tool30';
import Tool31 from '../components/Tool31';
import Tool32 from '../components/Tool32';
import Tool33 from '../components/Tool33';
import Tool34 from '../components/Tool34';
import Tool35 from '../components/Tool35';
import Tool36 from '../components/Tool36';
import Tool37 from '../components/Tool37';
import Tool38 from '../components/Tool38';
import Tool39 from '../components/Tool39';
import Tool40 from '../components/Tool40';
import Tool41 from '../components/Tool41';
import Tool42 from '../components/Tool42';
import Tool43 from '../components/Tool43';
import Tool44 from '../components/Tool44';
import Tool45 from '../components/Tool45';
import Tool46 from '../components/Tool46';
import Tool47 from '../components/Tool47';
import Tool48 from '../components/Tool48';
import Tool49 from '../components/Tool49';
import Tool50 from '../components/Tool50';
import Tool51 from '../components/Tool51';
import Tool52 from '../components/Tool52';
import Tool53 from '../components/Tool53';
import Tool54 from '../components/Tool54';
import Tool55 from '../components/Tool55';
import Tool56 from '../components/Tool56';
import Tool57 from '../components/Tool57';
import Tool58 from '../components/Tool58';
import Tool59 from '../components/Tool59';
import Tool60 from '../components/Tool60';
import Tool61 from '../components/Tool61';
import Tool62 from '../components/Tool62';
import Tool63 from '../components/Tool63';
import Tool64 from '../components/Tool64';
import Tool65 from '../components/Tool65';
import Tool66 from '../components/Tool66';
import Tool67 from '../components/Tool67';
import Tool68 from '../components/Tool68';
import Tool69 from '../components/Tool69';
import Tool70 from '../components/Tool70';
import Tool71 from '../components/Tool71';
import Tool72 from '../components/Tool72';
import Tool73 from '../components/Tool73';
import Tool74 from '../components/Tool74';
import Tool75 from '../components/Tool75';
import Tool76 from '../components/Tool76';
import Tool77 from '../components/Tool77';
import Tool78 from '../components/Tool78';
import Tool79 from '../components/Tool79';
import Tool80 from '../components/Tool80';
import Tool81 from '../components/Tool81';




const Team = () => {

                      let [modal1, setModal1] = useState(false);
                      let [modal2, setModal2] = useState(false);
                      let [modal3, setModal3] = useState(false);
                      let [modal4, setModal4] = useState(false);
                      let [modal5, setModal5] = useState(false);
                      let [modal6, setModal6] = useState(false);
                      let [modal7, setModal7] = useState(false);
                      let [modal8, setModal8] = useState(false);
                      let [modal9, setModal9] = useState(false);
                      let [modal10, setModal10] = useState(false);
                      let [modal11, setModal11] = useState(false);
                     

useEffect(()=>{
document.title = "Gallery | Brilliant Sanitation (U) Ltd";
},[]);

let modalOneFunc =()=>{
  setModal1(true);
}

let modalTwoFunc =()=>{
  setModal2(true);
}

let modalThreeFunc =()=>{
  setModal3(true);
}

let modalFourFunc =()=>{
  setModal4(true);
}

let modalFiveFunc =()=>{
  setModal5(true);
}

let modalSixFunc =()=>{
  setModal6(true);
}

let modalSevenFunc =()=>{
  setModal7(true);
}

let modalEightFunc =()=>{
  setModal8(true);
}

let modalNineFunc =()=>{
  setModal9(true);
}

let modalTenFunc =()=>{
  setModal10(true);
}
let modalElevenFunc =()=>{
  setModal11(true);
}

let closeModalOne = ()=>{
setModal1(false);

}

let closeModalTwo = ()=>{
setModal2(false);
}

let closeModalThree = ()=>{
setModal3(false);
}

let closeModalFour = ()=>{
setModal4(false);
}

let closeModalFive = ()=>{
setModal5(false);
}

let closeModalSix = ()=>{
setModal6(false);
}

let closeModalSeven = ()=>{
setModal7(false);
}

let closeModalEight = ()=>{
setModal8(false);
}

let closeModalNine = ()=>{
setModal9(false);
}

let closeModalTen = ()=>{
setModal10(false);
}

let closeModalEleven = ()=>{
setModal11(false);
}


let closeAll =(e)=>{
  if(e.target.classList.contains('modal-container')){
  setModal1(false);
  setModal2(false);
  setModal3(false);
  setModal4(false);
  setModal5(false);
  setModal6(false);
  setModal7(false);
  setModal8(false);
  setModal9(false);
  setModal10(false);
  setModal11(false);
  }

}

window.addEventListener('keydown',(e)=>{
 
  if(e.key === 'Escape'){
    setModal1(false);
    setModal2(false);
    setModal3(false);
    setModal4(false);
    setModal5(false);
    setModal6(false);
    setModal7(false);
    setModal8(false);
    setModal9(false);
    setModal10(false);
    setModal11(false);
  }

})


  return (
    <section className='section-cover pictorial-cover' >
       
          
          <h2 className="home-h2 team-h2">Gallery</h2>
             {/* <p className='about-intro-p'>Refurbished medical equipment with 1 year warranty included on sale below:</p> */}
         
{/* Cards Start */}
            <div className='services-container-new'>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool78></Tool78>
              </div>
            <p className='gallery-p'>Sanitation Accelerator Program - 2023 where Brilliant Sanitation (U) Ltd was among the few companies from Uganda that participated in it</p>
          </article>

          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool77></Tool77>
              </div>
            <p className='gallery-p'>Running order for introduction from left to right (kenya then Uganda) at the Sanitation Accelerator Program - 2023</p>
          </article>

          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool76></Tool76>
              </div>
            <p className='gallery-p'>CEO's participation in the IWA Non-Sewered Sanitation Conference held in Johannesburg, South Africa in 2023 where papers were presented, and participation was in panel discussions </p>
          </article>




          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool1></Tool1>
              </div>
            <p className='gallery-p'>Receiving a training certificate from Water for People </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool2></Tool2>
              </div>
            <p className='gallery-p'>Ready team for toilet emptying</p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool3></Tool3>
              </div>
            <p className='gallery-p'>Emptying temporary toilets at Old Kampala play ground where refugees were being updated. It was an Oxfam contract</p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool4></Tool4>
              </div>
            <p className='gallery-p'>Training new toilet emptiers in northern Uganda</p>
          </article>
        
         <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool5></Tool5>
              </div>
            <p className='gallery-p'>Toilet emptying trucks using gulping technology</p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool6></Tool6>
              </div>
            <p className='gallery-p'>
              'After a toilet emptying challenge' organized at Kampala Serena Hotel during AFWASA Conference held in 2020 and we won the competition
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool7></Tool7>
              </div>
            <p className='gallery-p'>
              Emptying toilets at Standard High School in Kapeeka town Nakaseke district 
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool8></Tool8>
              </div>
            <p className='gallery-p'>
              Emptying toilets in at a school in Kira
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool9></Tool9>
              </div>
            <p className='gallery-p'>
              Training new toilet emptiers in Apac district
              </p>
          </article>
         <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool10></Tool10>
              </div>
            <p className='gallery-p'>
             Certificates received from Jakarta Indonesia where world emptying challenge was organized and our team won
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool11></Tool11>
              </div>
            <p className='gallery-p'>
             Septic tank renovation
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool12></Tool12>
              </div>
            <p className='gallery-p'>
            Community sensitization in Nabisalu, Makindye
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool13></Tool13>
              </div>
            <p className='gallery-p'>
            Community toilet emptying in Kawempe division
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool14></Tool14>
              </div>
            <p className='gallery-p'>
            Transferring emptied sludge from our drums to the transfer tank for transportation
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool15></Tool15>
              </div>
            <p className='gallery-p'>
            Community sensitization in Kalerwe, Kawempe division
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool16></Tool16>
              </div>
            <p className='gallery-p'>
            Emptying toilets in Luwero district
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool17></Tool17>
              </div>
            <p className='gallery-p'>
            Training new toilet emptiers on how to use dumping bay
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool18></Tool18>
              </div>
            <p className='gallery-p'>
            Showing people how the gulping technology machine is used
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool19></Tool19>
              </div>
            <p className='gallery-p'>
           In slums of Kampala where cesspool trucks can't access
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool20></Tool20>
              </div>
            <p className='gallery-p'>
           Carrying out research under GIZ contract called Filling Times Project (FTP)
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool21></Tool21>
              </div>
            <p className='gallery-p'>
           The Managing Director at UN office for a sanitation meeting
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool22></Tool22>
              </div>
            <p className='gallery-p'>
           Kawempe Kiruddu hospital toilet cleaning
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool23></Tool23>
              </div>
            <p className='gallery-p'>
           During cholera outbreak in 2019, we were contracted by Water for People to empty all toilets in slums of Makindye, Lubaga and Kawempe divisions
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool24></Tool24>
              </div>
            <p className='gallery-p'>
           Emergency septic tank setting
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool25></Tool25>
              </div>
            <p className='gallery-p'>
           A modernized soak pit setting
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool26></Tool26>
              </div>
            <p className='gallery-p'>
           Sato pan installation on the pit latrines
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool27></Tool27>
              </div>
            <p className='gallery-p'>
           Toilet construction
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool28></Tool28>
              </div>
            <p className='gallery-p'>
           Septic tank emptying at National Council for Higher Education
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool29></Tool29>
              </div>
            <p className='gallery-p'>
           Plumbing
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool30></Tool30>
              </div>
            <p className='gallery-p'>
           Emptying toilets using cesspool trucks
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool31></Tool31>
              </div>
            <p className='gallery-p'>
           Temporary toilets set up 
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool32></Tool32>
              </div>
            <p className='gallery-p'>
           Hooking out the solid waste from the pit latrines
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool33></Tool33>
              </div>
            <p className='gallery-p'>
           Getting clients' feedback on our services
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool34></Tool34>
              </div>
            <p className='gallery-p'>
           Demonstrating how small drums are carried from the pit to the trucks
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool35></Tool35>
              </div>
            <p className='gallery-p'>
           Safe transportation of sludge to the truck
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool36></Tool36>
              </div>
            <p className='gallery-p'>
           Demonstrating to the community how we can empty their toilets using gulping technology at Nabisasilo zone, Makindye division
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool37></Tool37>
              </div>
            <p className='gallery-p'>
           Explaining our services to village leaders (LC chairpersons) at Lubaga division headquarters boardroom
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool38></Tool38>
              </div>
            <p className='gallery-p'>
           The Managing Director at National Water and Sewerage Corporation Lubigi plant boardroom for safe disposal of waste meeting
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool39></Tool39>
              </div>
            <p className='gallery-p'>
           Ladies are on our team
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool40></Tool40>
              </div>
            <p className='gallery-p'>
           In Lwamata Kigoga
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool41></Tool41>
              </div>
            <p className='gallery-p'>
           A nice modernized soak pit for households/tenants to pour in used water and in small space
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool42></Tool42>
              </div>
            <p className='gallery-p'>
           Septic Tank Construction
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool43></Tool43>
              </div>
            <p className='gallery-p'>
           Radio talk show on Beat FM
              </p>
          </article>
          
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool44></Tool44>
              </div>
            <p className='gallery-p'>
           Toilet was emptied and after upgraded 
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool45></Tool45>
              </div>
            <p className='gallery-p'>
         Our team at a school in Kiboga
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool46></Tool46>
              </div>
            <p className='gallery-p'>
         Toilet emptying equipment delivered to Hirja International in Kyangwali refugee camp 
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool47></Tool47>
              </div>
            <p className='gallery-p'>
         Mayor Nabbosa Ssebubwawo of Lubaga division authorizing toilet emptying in Lubaga division during Covid-19 period
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool48></Tool48>
              </div>
            <p className='gallery-p'>
        Toilet rescue emptying during Covid-19 period
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool49></Tool49>
              </div>
            <p className='gallery-p'>
       Sato pans installed already on the slabs
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool50></Tool50>
              </div>
            <p className='gallery-p'>
     Quick toilet setting
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool51></Tool51>
              </div>
            <p className='gallery-p'>
     Emergency toilet settings
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool52></Tool52>
              </div>
            <p className='gallery-p'>
     Emptying toilets in slums
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool53></Tool53>
              </div>
            <p className='gallery-p'>
     Temporary toilets set up at Agri Exim Limited in Mpererwe
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool54></Tool54>
              </div>
            <p className='gallery-p'>
    In Kole distict, northern Uganda
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool55></Tool55>
              </div>
            <p className='gallery-p'>
   Toilet upgrading
              </p>
          </article>
             <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool56></Tool56>
              </div>
            <p className='gallery-p'>
   Installation of a plastic septic tank
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool57></Tool57>
              </div>
            <p className='gallery-p'>
Modernized toilet
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool58></Tool58>
              </div>
            <p className='gallery-p'>
Equipment delivery at Oxfam Kyegegwa Kyaka Camp
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool59></Tool59>
              </div>
            <p className='gallery-p'>
Emptying school toilets in Kassanda district on the district contract
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool60></Tool60>
              </div>
            <p className='gallery-p'>
The Managing Director on the Feacal Sludge Management Alliance platforms after winning the sanitation challenge competition
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool61></Tool61>
              </div>
            <p className='gallery-p'>
New ordinary toilet block constructed in Buddo
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool62></Tool62>
              </div>
            <p className='gallery-p'>
Operators always ready for the site
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool63></Tool63>
              </div>
            <p className='gallery-p'>
Testing new technology in toilet emptying called Gulf 4
              </p>
          </article>
         <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool64></Tool64>
              </div>
            <p className='gallery-p'>
Radio talk show on Super FM
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool64></Tool64>
              </div>
            <p className='gallery-p'>
Radio talk show on Super FM
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool65></Tool65>
              </div>
            <p className='gallery-p'>
New toilet block and shower rooms in Kisoga Mukono district
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool66> </Tool66>
              </div>
            <p className='gallery-p'>
New septic tank and a soak pit in Bunamwaya
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool67> </Tool67>
              </div>
            <p className='gallery-p'>
Fumigation at a school in Mubende
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool68> </Tool68>
              </div>
            <p className='gallery-p'>
Sato stool installation on a toilet
              </p>
          </article>
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool69> </Tool69>
              </div>
            <p className='gallery-p'>
New toilet block in Kasangati
              </p>
          </article>
          
           <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool70> </Tool70>
              </div>
            <p className='gallery-p'>
Toilet emptying and renovation in Wobulenzi Luwero
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool71> </Tool71>
              </div>
            <p className='gallery-p'>
Dumping at Lubigi bay
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool72> </Tool72>
              </div>
            <p className='gallery-p'>
Upgrading the already set up toilet facilities
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool73> </Tool73>
              </div>
            <p className='gallery-p'>
New soak pit set up in Mutundwe
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool74> </Tool74>
              </div>
            <p className='gallery-p'>
The Managing Director in Abidjan - Ivory Coast for AFWASA Conference 2023 where sanitation experts and suppliers met up
              </p>
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool75> </Tool75>
              </div>
            <p className='gallery-p'>
Moving upcountry for toilets emptying
              </p>
          </article>
           {/* <article className='single-service-new'>
              <div className='tools-slides-cover'>
                <Tool76> </Tool76>
              </div>
            <p className='gallery-p'>
Temporary toilets set up at Agri Exim Limited in Mpererwe
              </p>
          </article> */}



      </div>

      {/* Cards End */}

      {/* Modals Start */}

      <section className={`${modal1?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalOne}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Stryker “M Series” Stretchers & Gurneys Refurbished</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Stryker “M Series” Stretchers and Gurneys Refurbished with 1 year warranty
and brand new mattress pads including Stryker 1005 model and Stryker 1015
big wheel – 5th wheel stretcher models. Popular for surgery centers, post anesthesia care, emergency departments and more.
</p>
          </div>

      </section>

       <section className={`${modal2?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTwo}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Skytron 6500 Elite Surgical Tables </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Skytron 6500 Elite Surgical Tables – Now only refurbished with 1 year parts warranty and new mattress pads included. These are one of the most reliable and durable surgery tables manufactured in the USA. </p>
          </div>
      </section>

      <section className={`${modal3?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalThree}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>GE Dinamap V100 Vital Sign Monitor </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>GE Dinamap V100 Vital Sign Monitor Includes NIBP, SP02, Temp with new battery, new cables and 1 year warranty with for trolley cart.</p>
          </div>
      </section>

      <section className={`${modal4?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFour}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Portable ultrasounds and ultrasound imaging equipment </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Portable ultrasounds and ultrasound imaging equipment - including Healcerion Sonon 300L, Sonon 300C, Sonoscape A6, Sonoscape S2, Eden portable ultrasounds, Sonosite 180 Plus ultrasounds, Soundmed ultrasounds and more including NEW OB GYN ultrasound equipment with convex, linear and transvaginal probe / transducers. Cardiac color ultrasounds with cardiac probes, convex probes and 1 year warranty. Ultrasounds for muscle skeletal, vascular, abdominal studies and more. GE, Philips, Siemens ultrasound units in stock now.</p>

          </div>
      </section>

      <section className={`${modal5?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalFive}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Welch Allyn Wall Mounted Otoscope, Opthalmascope Boards   </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Welch Allyn Wall Mounted Otoscope, Opthalmascope Boards  Welch Allyn 767 Otoscope, opthalmascope, blood pressure, specula disposable  and wall mount board.</p>
    </div>
      </section>

      <section className={`${modal6?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalSix}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Surgical Equipment </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Surgical Equipment – “OR” / Operating room tables, surgical lights, Anesthesia machines, defibrillators, ventilators, infusion pumps, electro surgical generators, crash carts, procedure chairs, endoscopy and laparoscopic equipment, stainless steel utensil trays, back tables, kick buckets, IV poles and more. </p>
    
    </div>
      </section>

      <section className={`${modal7?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalSeven}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Surgical Lights</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Operating Room / Surgical Equipment “OR” operating room / surgical lights – patient ready refurbished Berchtold D 660 / Berchtold D650 or lights – dual head. ALM surgical light sets – dual head,Skytron Infinity OR light sets.</p>
    
    </div>
      </section>

         <section className={`${modal8?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalEight}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Surgical Tables</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Operating Room “OR” / Surgical tables – patient ready – refurbished biomed certified Skytron 6001 operating room table,Skytron 6500 Elite OR tables,Skytron 6500 Elite “Hercules” bariatric OR table,Steris – Amsco 3080 and 3085 SP Operating room table,AMSCO 2080 “RC” electric surgical table,AMSCO 2080 “M” manual surgical table,Berchtold 810 operating room tables.</p>
    
    </div>
      </section>

       <section className={`${modal9?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalNine}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Doctor exam office medical equipment </h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Exam Tables – Exam Room Equipment including: Ritter and Midmark exam tables – Ritter 204, Ritter 108, Midmark 104, Midmark 304, Midmark 404 exam tables, 604 exam table, and power exam tables including the Ritter 222, Ritter 223, Midmark 305, Midmark 405, Midmark 622, 623 along with some Brewer and UMF exam table models.</p>
                   <p className='modal-p'>Other doctor exam room equipment includes mini stools, exam room lights, mayo stands, Welch Allyn otoscopes, ophthalmoscopes, wall mounted blood pressure, portable temperature gauges, Ritter M9 and M11 sterilizer /autoclaves and other exam room equipment.</p>
                   <p className='modal-p'>Power Procedure Chairs and Electric Exam Chairs including Mimark 75L, Midmark 111, Midmark 75 Evolution, Midmark 411 with pre-programmable positioning, Midmark 119, Midmark 417 Podiatry, chair, models and more. </p>
    </div>
      </section>
      <section className={`${modal10?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalTen}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Medical carts/medical crash carts, ER carts</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Medical carts/medical crash carts, ER carts: IV poles, mayo stands, back tables, stainless steel operating room equipment. Hill Rom and Stryker Hospital Beds sold used-refurbished and reconditioned</p>
                  
    </div>
      </section>

      <section className={`${modal11?'modal-container' :'modal-container hide-modal' }`}  onClick={closeAll}>
          <div className='modal-content'>

            <div  onClick={closeModalEleven}     className='modal-close-icon'>
              <FaTimes></FaTimes>
            </div>
               <h4 className='modal-h4'>Neo Natal infant equipment</h4>
               <div className='underline'> </div>
                  <p className='modal-p'>Neo Natal infant equipment: Drager Isolette® Infant Incubator (Models C2000 and C2000e) Refurbished – Drager Resuscitaire Infant Warmer baby cribs, infant scales, infant phototherapy lights and more. Looking for infant, NEO Natal, NICU medical equipment.</p>
                   
    </div>
      </section>

      {/* Modals End */}

        
    </section>
  )
}

export default Team