import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Our Services | Brilliant Sanitation (U) Ltd";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Toilet Emptying</h3>
                     <div className='line1'></div>
                     <p>
   We empty all types of toilets i.e.; septic tanks, VIP toilets, unlined toilets/ordinary toilets, etc using mechanical and semi-mechanized systems of toilet emptying using the following methods:
  </p>
  <ol type='a'>
    <li><strong>Cesspool trucks/vacuum trucks.</strong> This is for lined pits and septic tanks. We have trucks of both 4,000 and 10,000 litres.</li>
    <li><strong>Gulping technology.</strong> This technology is for ordinary/unlined toilets and for the pits located in slum areas where trucks can't access.</li>
  </ol>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Sato Pan Installation</h3>
                     <div className='line1'></div>
                     <p>
               They are installed by highly skilled and experienced experts at an affordable cost
                     </p>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>
                      Construction & Maintenance
                      </h3>
                       <div className='line1'></div>
                       <p>
            Soak pit setting, soak pit upgrading, soak pit construction, construction of toilets & construction of septic tanks
                     </p>
                     {/* <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>
                      General Cleaning, Compound Maintenance & Fumigation
                      </h3>
                    <div className='line1'></div>
                    {/* <p>These include agricultural seeds, laboratory reagents, laboratory equipment, clothing & textile materials, etc.</p> */}
                   
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>
          
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Community Sensitization</h3>
                       <div className='line1'></div>
                       <p>
           Information provision on matters regarding sanitation in order to prevent the outbreak of sanitation related epidemics due to floods or filled up latrines more especially in slum areas. 
                     
                     </p>
                     {/* <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>
            
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Plumbing Works</h3>
                    <div className='line1'></div>
                    {/* <p> </p> */}
                   
                  </article>

                  <article className='service-slides'>
                    <EmergencyCarousel></EmergencyCarousel>
                 
                  </article>     
            </div>
            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Supply of sanitation equipment</h3>
                       <div className='line1'></div>
                       <p>
            
                     
                     </p>
                     {/* <Link to='/rental-services' className='home-btn' onClick={scrollFunc}>Read More</Link> */}

                  </article>

                  <article className='service-slides'>
                    <BuildingsCarousel></BuildingsCarousel>
                 
                  </article>     
            </div>
             <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Consultancy Works</h3>
                    <div className='line1'></div>
                    {/* <p> </p> */}
                   
                  </article>

                  <article className='service-slides'>
                    <Plumbing></Plumbing>
                 
                  </article>     
            </div>
 </div>
    </section>
  )
}

export default Services